body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#head {
  h2 {
    margin-bottom: 0;
  }
}

#title-block {
  &-name {
    font-size: 25px;
    font-weight: 600;
  }
  &-tool {
    font-size: 20px;
    margin-left: 5px;
  }
}

#gridio-logo {
  width: 180px;
  padding: 20px 0;
  margin: 0 auto;

  &-front {
    width: 60px;
  }
}

.login {
  padding: 20px;

  form {
    padding: 0 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > input {
      width: 100%;
      border: 1px solid #bbbbbb;
      margin-bottom: 15px;
      padding: 10px;
      font-size: 15px;
      box-sizing: border-box;
      -webkit-appearance: none;
      border-radius: 5px;
    }

    button {
      width: 50%;
      margin-top: 15px;
      background: #44c4e7;
      color: #fff;
      padding: 10px;
      font-size: 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: #369cb8;
      }
    }
  }
}

.container {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  text-align: center;
  margin: 20px;
}

.dataform {
  display: flex;
}

.inputContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 10px;
}

.inputRadius {
  text-align: center;
  border: 1px solid #bbbbbb;
  //border-style: inset;
  height: 60px;
  border-radius: 5px;
  width: 60px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 45px;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-appearance: none;
  padding: 0;
}

.txtInput {
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
  font-size: 25px;
}


.prompt {
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  h3 {
    margin: 10px 0;
  }
}

.connection-status {
  font-size: 20px;
  text-decoration: none;
  margin-top: 15px;
  text-align: center;
  margin-bottom: 15px;

  &-fail {
    color: red;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
  }
  ol {
    text-align: left;
    line-height: 25px;
  }

  &-success {
    color: green;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
  }
}

#hashtag {
  font-size: 50px;
  font-weight: 600;
}

#uid {
  display: none;
  position: relative;
  width: 320px;
  margin: -130px auto 0;
  &-img {
    width: 100%;
  }
}

#questionmark, #closeimg {
  background: #61dafb;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 25px;
  height: 30px;
  width: 30px;
  display: flex;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  float: right;
}

.status {

  &-body {
    line-height: 12px;
    text-align: left;

    &-stage {
      margin-top: 5px;
      padding: 5px;
      display: inline-block;
      font-size: 15px;
      line-height: 15px;

      &.asterisk {
        color: #d80404;
        font-size: 14px;
      }
    }
  }

  &-panel {
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    background: #fff;
    margin: 5px 0 0;
  }

  &-row {
    border-bottom: #d2d2d2 1px solid;
    border-radius: 5px;
    padding: 0 5px 5px;
    transition: 0.5s ease-in;
    margin: 10px 0 0;

    &:last-child {
      margin-bottom: 2px;
      border-bottom: none;
    }

    .key {
      font-size: 18px;
      text-align: left;
    }

    .value {
      font-size: 25px;
      float: right;
      font-weight: 600;
    }

    #address {
      font-size: 15px;
    }

    #status {
      text-transform: uppercase;
      font-size: 16px;
    }

    #online {
      font-size: 20px;
      color: forestgreen;
      text-transform: uppercase;
    }

    #signal {
      font-size: 20px;
    }

    #imei {
      font-size: 15px;
    }

    #carrier {
      font-size: 18px;
    }

    #fw {
      font-size: 17px;
    }
  }
}

.phase {
  height: 110px;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;

  &:not(:last-child) {
    border-bottom: 1px solid #bbbbbb;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    line-height: normal;
    background-color: #FFF;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
  }

  &-info {
    display: inline-flex;
    align-items: baseline;
    flex-grow: 1;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 15px;
    margin: 5px 0 0 5px;
  }

  &-metrics {
    margin: 3px 0 0;
    display: flex;
    align-items: center;
  }

  &-blink {
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
    background: #cf0400;
    height: 15px;
    width: 15px;

    &.active {
      background: #11c521;
    }
  }

  &-id {
    font-size: 60px;
    padding: 15px 0 0 5px;
    letter-spacing: -10px;

    &-num {
      line-height: 2.8;
      font-size: 35px;
    }
  }

  &-check {
    font-size: 15px;
    margin: 10px 0;
  }

  &-current {

  }

  &-voltage, &-current {
    margin: 0 15px 0 2px;
    font-size: 15px;
    font-weight: 600;
  }

}

.information {

  &-box {
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    width: 100%;
    height: 150px;
    display: flex;
    font-size: 20px;
    font-family: inherit;
    box-sizing: border-box;
    -webkit-appearance: none;
    padding: 5px;
    resize: none;

    &::placeholder {
      color: #bbbbbb;
    }
  }
  &-attachment {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;

    input[type=file] {
      position: absolute;
      width: 70%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
    }
    #upload-btn {
      width: 100%;
      margin-right: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
    }
    .btn {
      border: 1px solid #bbbbbb;
      color: #000;
      background-color: white;
      padding: 8px 20px;
      border-radius: 5px;
      font-size: 14px;
      margin-bottom: 10px;
      cursor: pointer;
      &#submit-data:disabled {
        opacity: 0.3;
      }
    }

    #submit-data {
      width: 120px;
    }

  }

}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.toggle {
  font-size: 15px;
  padding: 5px 0;
  display: block;
  text-align: right;
  text-transform: uppercase;
  text-decoration: underline;
}

.arrow-closed, .arrow-open {
  border: solid #999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 10px;
}

.arrow-closed {
  top: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-open {
  top: 14px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.Dropdown-root {
  position: relative;
  margin-top: 10px;
  //flex-grow: 1;
  width: 100%;
}

.Dropdown-control {
  max-width: 220px;
  position: relative;
  background-color: white;
  border: 1px solid #bbb;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 8px 20px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1.2);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-placeholder.is-selected {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Dropdown-control.Dropdown-disabled {
  opacity: 0.5;
}